.left-caret-bg {
  height: 5vw;
  background-image: url('../images/OtherImages/leftCaret.svg');
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;

  @include for-tablet-landscape-up {
    height: 20px;
  }
}
