.owner-header {
  z-index: 2001;
}

.header-width {
  max-width: 100vw;
}

.owner-header-footer {
  margin: 0 auto;
}

.max-width {
  max-width: 1440px;
  width: 100%;
}
