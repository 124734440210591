.dialog {
  z-index: 9999;

  &__content {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--fds-color--white);
    box-shadow: var(--box-shadow);
    overflow: auto;

    position: absolute;
    width: 100%;
    margin: 0;
    padding: 48px 24px 128px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    --close-btn-pos-y: 4rem;

    @include for-tablet-landscape-up {
      position: relative;
      width: var(--content-width);
      margin: var(--content-margin);
      padding: 48px 32px;

      --close-btn-pos-y: 2.75rem;
      --close-btn-font-size: 1.6rem;
    }

    .fds-font--ford-icons__clear::before {
      @include for-tablet-landscape-up {
        margin-right: 24px;
        font-size: 2.4rem;
      }
    }

    &__title {
      margin-top: 24px;
      margin-bottom: 8px;
      font-size: 3.2rem;
      font-weight: var(--fmc-type__weight-light);

      @include for-tablet-landscape-up {
        margin-top: 8px;
      }

      @include lincoln {
        font-size: 2.8rem;
        font-weight: var(--fmc-type__weight-normal);
      }
    }

    &__subtitle {
      color: var(--fds-color--gray2);
      font-size: 2rem;
      margin-top: 8px;

      @include lincoln {
        font-size: 1.6rem;
        color: var(--fds-color--primary);
        font-weight: 400;
      }
    }

    &__body {
      margin-top: 32px;
    }

    &__buttons-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 32px;

      @include for-tablet-landscape-up {
        flex-direction: row;
      }

      @include lincoln {
        margin-top: 24px;
      }

      &__button {
        width: fit-content;
        margin-top: 32px;
        font-weight: var(--fmc-type__weight-normal);

        @include lincoln {
          font-weight: var(--fmc-type__weight-medium);
        }

        @include for-tablet-landscape-up {
          margin-top: 1rem;
          margin-right: 32px;
        }
      }
    }
  }
}

%dialog-secion-title {
  font-size: 1.8rem;

  @include lincoln {
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}

%dialog-paragraph {
  color: var(--fds-color--gray3);
  margin-top: 8px;
  line-height: 2.4rem;

  @include for-tablet-landscape-up {
    margin-right: 2.6rem;
  }

  @include lincoln {
    --fmc-type-size: 1.6rem;
    color: var(--fds-color--primary);
  }
}
