.unlink-third-party-tile {
  padding-top: 24px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;

    &__title {
      font-size: 2.4rem;
      color: var(--fds-color--primary);

      @include lincoln {
        letter-spacing: 2px;
        text-transform: uppercase;
      }
    }
  }

  &__description {
    padding-bottom: 24px;
    color: var(--fds-color--primary);
  }

  &__feature-wrapper {
    border-bottom: 1px solid var(--fds-color--black);
    background-color: var(--fds-color--white);

    @include lincoln {
      background-color: var(--fds-color--gray1);
      border-bottom: 1px solid var(--fds-color--gray2);
    }
  }

  &__feature-button-wrapper {
    padding: 16px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--fds-color--black);
    width: 100%;

    @include lincoln {
      border-top: 1px solid var(--fds-color--gray2);
    }
  }

  &__button-text-wrapper {
    color: var(--fds-color--primary);
    text-align: left;

    @include lincoln {
      text-transform: uppercase;
    }
  }

  &__chevron-wrapper {
    color: var(--fds-color--primary);

    @include lincoln {
      color: var(--fds-color--secondary);
    }
  }
}
