.select-vehicle {
  padding-top: 25px;

  &__label {
    font-size: 11px;
    line-height: 16px;
    padding-bottom: 12px;
    color: var(--fds-color--gray3);
  }

  &__input {
    color: var(--fds-color--gray3);
    caret-color: transparent;
    cursor: default;

    &:focus {
      --shadow: none;
      --background-color: transparent;
      --border-bottom-weight: none;
      --border-bottom: var(--border-all);
      outline: none;
    }

    @include for-tablet-landscape-up {
      width: fit-content;
      min-width: 33%;
    }
  }

  &__select {
    @include for-tablet-landscape-up {
      width: fit-content;
      min-width: 33%;
    }
  }

  &__floating-label {
    &:focus {
      outline: none;
    }

    &:focus-within {
      outline: none;
    }
  }

  @include lincoln {
    &__input {
      &:focus {
        background-color: var(--fds-color--gray2);
        border-bottom-color: var(--fds-color--primary);
        border-bottom: var(--border-bottom-weight) solid var(--border-color);
        --border-bottom-weight: 0.1rem;
      }
    }

    &__floating-label__label[for='select-vehicle-readonly-input'] {
      cursor: default;
    }
  }
}
