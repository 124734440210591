%unlink-icon {
  height: 64px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 64px 64px;
  min-width: 64px;

  @include for-tablet-landscape-up {
    height: 96px;
    background-size: 96px 96px;
    min-width: 96px;
  }
}

.unlink-feature {
  background-color: var(--fds-color--gray1);
  min-height: 100vh;
  width: 100%;
  max-width: 1440px;

  @include lincoln {
    background-color: var(--fds-color--white);

    @include for-tablet-landscape-up {
      background-color: var(--fds-color--gray1);
    }
  }

  &__amazon-alexa-image {
    @extend %unlink-icon;
    background-image: url('../images/manageDataImages/Alexa-Smile-Logo-Blue.svg');
  }

  &__polaris-image {
    @extend %unlink-icon;
    background-image: url('../images/manageDataImages/Polaris_Logo.svg');
  }

  &__ford-connect-image {
    @extend %unlink-icon;
    background-image: url('../images/manageDataImages/Ford-Logo.svg');
    background-size: contain;
  }

  &__2hire-image {
    @extend %unlink-icon;
    background-image: url('../images/manageDataImages/2hire_Logo.svg');
  }

  &__caruso-image {
    @extend %unlink-icon;
    background-image: url('../images/manageDataImages/Caruso_Logo.svg');
  }

  &__high-mobility-image {
    @extend %unlink-icon;
    background-image: url('../images/manageDataImages/High_Mobility_Logo.svg');
  }

  &__octopus-energy-image {
    @extend %unlink-icon;
    background-image: url('../images/manageDataImages/Octopus_Energy.svg');
  }

  &__tibber-image {
    @extend %unlink-icon;
    background-image: url('../images/manageDataImages/Tibber_Logo.svg');
    background-size: 104px 64px;
  }

  &__go-back-cta {
    @extend %go-back-cta;
  }

  &__nav-bar {
    padding: 32px;

    @include for-tablet-landscape-up {
      padding-left: 100px;
      padding-right: 100px;
      background-color: var(--fds-color--white);
    }

    @include lincoln {
      padding-bottom: 32px;

      @include for-tablet-landscape-up {
        padding-left: 45px;
      }
    }

    &__header {
      display: none;

      @include for-tablet-landscape-up {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 48px;
        padding-bottom: 48px;
      }

      @include lincoln {
        @include for-tablet-landscape-up {
          padding-left: 55px;
        }
      }

      &__title {
        font-size: 2.4rem;
        color: var(--fds-color--primary);

        @include for-tablet-landscape-up {
          font-weight: 500;
          font-size: 3.2rem;
        }

        @include lincoln {
          letter-spacing: 2px;
          text-transform: uppercase;

          @include for-tablet-landscape-up {
            font-weight: 400;
            font-size: 2.4rem;
          }
        }
      }
    }
  }

  &__content {
    padding: 32px;

    @include for-tablet-landscape-up {
      text-align: center;
      padding-left: 100px;
      padding-right: 100px;
    }

    @include lincoln {
      padding-top: 0;

      @include for-tablet-landscape-up {
        padding-top: 80px;
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__title {
      margin-top: 32px;
      font-size: 2.4rem;
      color: var(--fds-color--primary);
      text-align: center;

      @include for-tablet-landscape-up {
        font-size: 3.2rem;
        margin-top: 42px;
      }

      @include lincoln {
        margin-top: 52px;
        letter-spacing: 2px;
        text-transform: uppercase;

        @include for-tablet-landscape-up {
          margin-top: 42px;
          font-size: 2.4rem;
        }
      }
    }

    &__date-of-consent {
      margin-top: 24px;
      font-size: 1.2rem;
      color: var(--fds-color--gray2);
      text-align: center;

      @include for-tablet-landscape-up {
        margin-top: 42px;
        margin-bottom: 16px;
        font-size: 1.8rem;
      }
      @include lincoln {
        color: var(--fds-color--primary);
        margin-top: 10px;
        margin-bottom: 16px;

        @include for-tablet-landscape-up {
          margin-top: 32px;
        }
      }
    }
  }

  &__learn-more-wrapper {
    padding: 16px 0;
    color: var(--fds-color--gray2);

    @include for-desktop-med {
      padding: 16px 20%;
    }

    @include lincoln {
      color: var(--fds-color--primary);
      font-size: 1.6rem;
    }
  }

  &__learn-more-button {
    @extend %button-link;
    --underline-offset: 0.1rem;
    --text-decoration: underline;
    --underline-color: var(--fds-color--primary);
    line-height: var(--fmc-type-leading);
  }

  &__unlink-account-button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 32px;

    @include for-tablet-landscape-up {
      margin-top: 48px;
    }

    @include lincoln {
      margin-top: 16px;
    }
  }
}
