.feature-tile {
  padding-top: 24px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      font-size: 1.8rem;
      color: var(--fds-color--primary);

      @include lincoln {
        letter-spacing: 2px;
        text-transform: uppercase;
      }
    }
  }

  &__learn-more {
    margin-bottom: 24px;
  }

  &__last-update-info {
    margin-bottom: 24px;
    color: var(--fds-color--gray3);

    @include lincoln {
      --fmc-type-size: 1.6rem;
      font-size: var(--fmc-type-size);
      color: var(--fds-color--primary);
    }
  }

  &__link-wrapper {
    margin-bottom: 24px;

    &__link {
      @extend %button-link;
      text-decoration: underline;

      @include lincoln {
        font-weight: 600;
      }
    }
  }
}
