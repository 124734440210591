div[role='main'] {
  background-color: var(--fds-color--fourth);

  @include lincoln {
    background-color: var(--fds-color--white);

    @include for-tablet-landscape-up {
      background-color: var(--fds-color--gray1);
    }
  }
}

.landing-page {
  background-size: cover;
  background-position: center bottom 10%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(rgba(0, 0, 0, 55%), rgba(0, 0, 0, 55%)),
    url('../images/landingPageImages/landingPage.jpg');
  min-height: 25vh;
  padding: 25px;

  &--left {
    align-items: start;

    @include for-tablet-landscape-up {
      padding-left: 100px;
    }
  }

  @include for-tablet-landscape-up {
    min-height: 33vh;
  }

  @include lincoln {
    background-image: linear-gradient(0deg, #00142e 0%, rgba(0, 0, 0, 0) 100%),
      url('../images/landingPageImages/landingPage-Lincoln.jpg');
    background-position: center;
    box-shadow: 200px 200px 200px 0px rgba(0, 0, 0, 0.4) inset;
  }

  &__heading {
    color: var(--fds-color--white);
    font-size: $pcu-billboard-font-size-lg;
    line-height: 36px;
    font-weight: 500;
    letter-spacing: 0;

    @include for-tablet-landscape-up {
      font-size: 7.5rem;
      line-height: 88px;
    }

    @include lincoln {
      & span:first-child {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 2px;
        font-family: var(--fmc-font--miller);
        text-transform: uppercase;

        @include for-tablet-landscape-up {
          font-size: 5rem;
          font-weight: 800;
          line-height: 60px;
          text-transform: none;
        }
      }
    }
  }

  &__heading-info {
    color: #ffffff;
    font-size: 1.4rem;
    line-height: 24px;
    padding: 16px 0 0;
    font-weight: var(--fmc-type__weight-light);

    @include for-tablet-landscape-up {
      max-width: 65%;
      padding: 20px 12px 0;
    }

    @include lincoln {
      font-size: 1.4rem;

      @include for-tablet-landscape-up {
        max-width: 45%;
        font-size: 1.8rem;
      }
    }
  }

  &__info {
    padding: 35px 25px 0;
    color: var(--fds-color--gray3);
    line-height: 24px;

    @include for-tablet-landscape-up {
      padding: 50px 7.639vw 30px;
      display: flex;
      flex-direction: row;
    }

    @include lincoln {
      color: var(--fds-color--primary);
    }

    &--no-tile {
      text-align: center;
      font-size: 1.6rem;
      padding: 24px;
      line-height: 2.4rem;
      color: var(--fds-color--gray3);
      flex-direction: column;

      @include for-tablet-landscape-up {
        font-size: 2.4rem;
        padding: 45px 180px;
        line-height: 3.6rem;

        @include lincoln {
          font-size: 1.8rem;
          max-width: 75%;
          margin: auto;
        }
      }

      @include for-desktop-med {
        font-size: 2.8rem;
        padding: 45px 180px;
        line-height: 3.6rem;
        @include lincoln {
          font-size: 1.8rem;
          max-width: 75%;
          margin: auto;
        }
      }
    }
  }

  &__divider {
    height: 1px;
    opacity: 0.4;
    transform: scaleX(-1);
    background-color: var(--fds-color--black);
    margin: 40px 25px 15px;
    max-width: 975px;

    @include for-tablet-landscape-up {
      margin: 40px auto 15px;
    }
  }
}
