.accordion {
  &__panel {
    @extend .fmc-accordion__panel;

    @include lincoln {
      --expand-icon-size: 3.2rem;
      --expand-icon-symbol: '\e906';
      --expand-icon-symbol--expanded: '\e906';
      --expand-icon-transform--expanded: rotate(-180deg);
    }
  }

  &__expand-icon {
    &::after {
      font-weight: var(--fmc-type__weight-bold);
    }

    @include lincoln {
      display: grid;

      &::after {
        font-weight: var(--fmc-type__weight-light);
      }
    }
  }
}
