.manage-data-tile {
  text-align: left;
  padding: 30px 0 0;

  @include for-tablet-landscape-up {
    padding: 0;
    background-color: white;
    text-align: center;
    margin-right: 25px;
    max-width: 41.46vw;
    flex: 1 0 0;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;

    @include for-tablet-landscape-up {
      margin-right: 0;
    }
  }

  &__image {
    height: 36.3vw;
    background-size: cover;
    background-repeat: no-repeat;

    @include for-tablet-landscape-up {
      height: 260px;
    }

    &--ford {
      background-image: url('../images/landingPageImages/manageDataWithFord.jpg');
      background-position: top;

      @include lincoln {
        background-image: url('../images/landingPageImages/manageDataWithLincoln.jpg');
        background-position: center;
      }
    }

    &--third-party {
      background-image: url('../images/landingPageImages/manageDataWithTP-Ford.jpg');
      background-position: center;

      @include lincoln {
        background-image: url('../images/landingPageImages/manageDataWithThirdParties.jpg');
        background-position: top;
      }
    }
  }

  &__content {
    padding-top: 25px;

    @include for-tablet-landscape-up {
      padding: 25px 12px 50px;

      @include lincoln {
        padding: 25px 32px 50px;
      }
    }

    &__header {
      color: var(--fds-color--primary);
      font-size: $pcu-font-size-sm;
      letter-spacing: normal;
      line-height: 24px;
      padding-bottom: 10px;

      @include for-tablet-landscape-up {
        font-size: 24px;
        line-height: 28px;
        padding-bottom: 16px;
      }

      @include lincoln {
        letter-spacing: 2px;
        text-transform: uppercase;
      }
    }

    &__desc {
      color: var(--fds-color--gray3);
      line-height: 24px;
      font-size: 1.4rem;

      @include for-tablet-landscape-up {
        font-size: 1.6rem;
      }

      @include lincoln {
        color: var(--fds-color--primary);
        font-size: 1.6rem;
      }
    }

    &__button-wrapper {
      padding: 30px 0 0;

      @include for-tablet-landscape-up {
        margin: 0 auto;
        display: flex;
        justify-content: center;
      }
    }
  }
}
