.paragraph-truncated {
  margin-top: 16px;
  margin-bottom: 24px;

  &__content {
    color: var(--fds-color--gray3);

    @include for-tablet-landscape-up {
      max-width: 80%;
    }

    @include lincoln {
      --fmc-type-size: 1.6rem;
      color: var(--fds-color--primary);
    }
  }

  &__button {
    @extend %button-link;
    line-height: normal;
    margin-left: 2px;
    text-transform: lowercase;
  }
}
