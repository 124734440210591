.error-banner {
  z-index: 99999;
  position: fixed;
  left: 0;
  top: 0;

  &__refresh-button {
    &::before {
      --fds-font--ford-icons__clear: none;
    }

    & svg {
      height: var(--dismiss-icon-size);
      width: var(--dismiss-icon-size);
    }

    @include lincoln {
      & svg {
        padding: 0.4rem;
      }
    }
  }
}
