.mobile-app-cookies-button {
  align-self: center;

  &__content {
    padding-top: 25px;

    @include for-tablet-landscape-up {
      padding: 25px 12px 50px;

      @include lincoln {
        padding: 25px 32px 50px;
      }
    }

    &__button-wrapper {
      padding: 30px 0 0;

      @include for-tablet-landscape-up {
        margin: 0 auto;
        display: flex;
        justify-content: center;
      }
    }
  }
}
