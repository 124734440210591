@use 'sass:math';

@mixin for-iphone5-only {
  @media (max-width: 330px) {
    @content;
  }
}

@mixin for-samsung-s5-only {
  @media (max-width: 360px) {
    @content;
  }
}

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin for-tablet {
  @media (min-width: 614px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin for-desktop-med {
  @media (min-width: 1440px) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-mobile-height-medium {
  @media (max-height: 650px) {
    @content;
  }
}

@mixin lincoln {
  @at-root #{selector-nest('[data-brand="lincoln"]', &)} {
    @content;
  }
}
