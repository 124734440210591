.flex {
  display: flex;
}

.flex-center {
  justify-content: center;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-item-center {
  align-items: center;
}

.align-center {
  text-align: center;
}

.background-color-F {
  background-color: #fafafa;
}

.background-color-L {
  background-color: #ecedee;
}

.bg-white {
  background-color: #ffffff;
}

.common-wrapper {
  padding: 40px 25px 30px;
}

.min-h-100vh {
  min-height: 100vh;
}

.w-100 {
  width: 100%;
}
