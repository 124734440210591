%go-back-cta {
  display: flex;
  align-items: baseline;
  color: var(--fds-color--primary);
  font-size: 18px;
  font-stretch: condensed;
  line-height: 24px;
  letter-spacing: 1px;

  @include lincoln {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
    align-items: center;
  }

  & .fds-icon::before {
    font-size: 1.5rem;
    font-weight: 900;

    @include lincoln {
      font-size: 2.5rem;
      font-weight: 400;
    }
  }
}

%button-link {
  --underline-offset: 0.1rem;
  --text-decoration: underline;
  --underline-color: var(--fds-color--primary);

  @include lincoln {
    --underline-offset: 0.4rem;
    --underline-color: var(--fds-color--secondary);
    --underline-weight: 0.2rem;

    text-decoration-color: var(--underline-color);
    text-underline-offset: var(--underline-offset);
    text-decoration-thickness: var(--underline-weight);
    font-size: 1.6rem;
  }

  &:focus:not(:focus-visible) {
    --text-decoration: underline;
  }

  &:hover {
    --underline-color: var(--text-color-hover);

    @include lincoln {
      --underline-color: var(--fds-color--primary);
    }
  }
}

.fmc-top-banner button[aria-hidden='true'] {
  visibility: hidden;
}
