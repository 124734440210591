.bottom-links {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__link-wrapper {
    text-align: center;
    margin: 15px 0;

    @include lincoln {
      margin: 8px 0;
    }
  }

  &__privacy-choices-icon {
    margin-left: 10px;
    background-image: url('../images/landingPageImages/privacyChoicesIcon.svg');
    height: 25px;
    width: 35px;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__link {
    @extend %button-link;
    font-size: 1.6rem;
    --text-decoration: none;

    &:hover {
      --underline-color: var(--text-color-hover);
      --text-decoration: underline;
    }
  }
}
