.manage-data {
  background-color: var(--fds-color--gray1);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include lincoln {
    background-color: var(--fds-color--white);

    @include for-tablet-landscape-up {
      background-color: var(--fds-color--gray1);
    }
  }

  &__go-back-cta {
    @extend %go-back-cta;
  }

  &__header-container {
    padding: 32px 24px 16px 24px;

    @include for-tablet-landscape-up {
      padding-left: 100px;
      padding-right: 100px;
      background-color: var(--fds-color--white);
    }

    @include lincoln {
      padding-bottom: 32px;

      @include for-tablet-landscape-up {
        padding-left: 45px;
      }
    }

    &--no-select-option {
      @include for-tablet-landscape-up {
        padding-bottom: 84px;
      }
    }
  }

  &__body {
    padding: 24px;

    @include for-tablet-landscape-up {
      padding-left: 100px;
      padding-right: 100px;
    }
  }

  &__select-vehicle {
    @include lincoln {
      @include for-tablet-landscape-up {
        padding-left: 55px;
      }
    }

    &--extra-margin-bottom {
      margin-bottom: 36px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 48px;

    @include lincoln {
      @include for-tablet-landscape-up {
        padding-left: 55px;
      }
    }

    &__title {
      font-size: 2.4rem;
      color: var(--fds-color--primary);

      @include for-tablet-landscape-up {
        font-weight: 500;
        font-size: 3.2rem;
      }

      @include lincoln {
        letter-spacing: 2px;
        text-transform: uppercase;

        @include for-tablet-landscape-up {
          font-weight: 400;
          font-size: 2.4rem;
        }
      }
    }
  }

  &__go-back-button-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 24px;
    padding-bottom: 24px;
  }
}
