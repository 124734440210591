.turn-on-off-dialog {
  &__section-title {
    @extend %dialog-secion-title;

    @include lincoln {
      font-size: 2rem;
    }
  }

  &__vehicle-name {
    @extend %dialog-paragraph;
    margin-top: 8px;
    font-size: 1.6rem;
  }

  &__paragraph {
    @extend %dialog-paragraph;
    margin-top: 32px;
    font-size: 1.6rem;
    white-space: pre-line;

    @include for-tablet-landscape-up {
      margin-top: 24px;
    }

    @include lincoln {
      margin-top: 32px;
    }
  }
}
