.unlink-vehicle-dialog {
  &__section-title {
    @extend %dialog-secion-title;

    @include lincoln {
      font-size: 2rem;
    }
  }

  &__vehicle-name {
    @extend %dialog-paragraph;
    margin-top: 8px;
    font-size: 1.6rem;
  }

  &__paragraph {
    @extend %dialog-paragraph;
    font-size: 1.6rem;
    margin: 32px 0;

    &:last-child {
      margin-bottom: 0;
      font-size: 1.2rem;
    }

    @include for-tablet-landscape-up {
      margin: 24px 0;
    }
  }
}
