.learn-more-dialog {
  &__section-title {
    @extend %dialog-secion-title;
  }

  &__paragraph {
    @extend %dialog-paragraph;
  }

  &__link {
    display: inline-block;
    position: relative;
    margin-top: 8px;
  }

  &__accordion {
    margin-top: 32px;
  }

  & .dialog__content__buttons-wrapper {
    @include for-tablet-landscape-up {
      display: none;
    }
  }
}
