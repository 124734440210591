.mac-page {
  background-color: var(--fds-color--gray1);
  min-height: 100vh;

  &__flex {
    display: flex;
  }

  &__go-back-cta {
    @extend %go-back-cta;
  }

  &__header {
    padding: 32px 32px 0;

    @include for-tablet-landscape-up {
      padding-bottom: 32px;
      padding-left: 100px;
      padding-right: 100px;
      background-color: var(--fds-color--white);
    }

    @include lincoln {
      padding-bottom: 32px;

      @include for-tablet-landscape-up {
        padding-left: 45px;
      }
    }
  }

  &__content {
    padding: 0 32px 32px;
    color: var(--fds-color--gray3);

    @include for-tablet-landscape-up {
      padding-left: 100px;
      padding-right: 100px;
    }
  }

  &__title {
    color: var(--fds-color--primary);
    font-size: 2.4rem;
    letter-spacing: normal;
    line-height: 24px;
    margin-top: 32px;
    margin-bottom: 0;

    @include for-tablet-landscape-up {
      font-weight: 500;
      font-size: 3.2rem;
      margin-top: 48px;
      margin-bottom: 48px;
    }

    @include lincoln {
      font-size: 2.4rem;
      text-transform: uppercase;

      @include for-tablet-landscape-up {
        padding-left: 55px;
        font-weight: 400;
      }
    }
  }

  &__desc-text {
    margin-top: 32px;
    margin-bottom: 16px;
    line-height: 26px;

    @include for-tablet-landscape-up {
      margin-bottom: 0;
    }
  }

  &__footer {
    margin-top: 48px;
    text-align: center;
    color: var(--fds-color--gray2);
    font-size: 1.6rem;
    letter-spacing: 1px;
    line-height: 21px;

    @include for-tablet-landscape-up {
      margin-top: 130px;
    }

    @include lincoln {
      color: var(--fds-color--gray3);
    }
  }

  &__go-back-button {
    min-width: 180px;
    margin-top: 32px;
    margin-bottom: 32px;
    justify-content: center;
    display: flex;
  }

  &__hyper-link {
    text-decoration: underline;
    cursor: pointer;
    padding-left: 5px;
  }

  &__mac-tile {
    padding-top: 16px;

    @include for-tablet-landscape-up {
      padding-top: 40px;
    }

    &__sub-header {
      font-size: $pcu-font-size-sm;
      color: var(--fds-color--primary);
    }

    &__name {
      display: inline-block;
      height: 24px;
      width: 195px;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 24px;
    }

    &__name-and-toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__description_text {
      margin-top: 24px;
    }

    &__timestamp {
      font-size: 1.2rem;
      margin-top: 16px;

      @include for-tablet-landscape-up {
        margin-top: 32px;
      }
    }

    &__divider {
      padding-top: 16px;

      @include for-tablet-landscape-up {
        padding-top: 40px;
      }
    }
  }
}
