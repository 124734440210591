.loading {
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255 255 255 / 50%);
  z-index: 99999;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  transform: scaleX(-1);

  .spinner {
    color: #000000;
    width: 48px;
    height: 48px;
    transform-origin: 50% 50%;
    animation: rotating 2s ease-in-out infinite;

    &__svg {
      &__circle {
        stroke: currentColor;
        stroke-dasharray: 70px 200px;
        stroke-dashoffset: 100;
        animation: dash 2s ease-in-out infinite;
      }
    }
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 70;
  }
  100% {
    stroke-dashoffset: -125;
  }
}

@keyframes rotating {
  0% {
    transform: rotate(-90deg);
  }
  90%,
  100% {
    transform: rotate(270deg);
  }
}
